<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <a href="#" @click.prevent="prev" class="fullcalendar-btn-prev btn btn-sm btn-default">
            <i class="fas fa-angle-left"></i>
          </a>
          <a href="#" @click.prevent="next" class="fullcalendar-btn-next btn btn-sm btn-default">
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridMonth'}"
                       @click="changeView('dayGridMonth')">
            Month
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'dayGridWeek'}"
                       @click="changeView('dayGridWeek')">
            Week
          </base-button>
          <base-button class="btn btn-sm btn-default"
                       :class="{'active': defaultView === 'timeGridDay'}"
                       @click="changeView('timeGridDay')">
            Day
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">Calendar - {{ this.currentMonth }}</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <full-calendar :events="events"
                             :plugins="calendarPlugins"
                             :editable="true"
                             contentHeight="auto"
                             :theme="false"
                             :selectable="true"
                             :selectHelper="true"
                             ref="fullCalendar"
                             class="calendar"
                             :defaultView="month"
                             @dateClick="onDateClick"
                             @eventClick="onEventClick"
              >
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showAddModal" v-if=this.$store.state.isAdmin modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input 
                    label="Name"
                    placeholder="Appointment Name"
                    v-model="model.name"
                    input-classes="form-control-alternative new-event--title">
        </base-input>
              <base-input label="Client">
                <el-select v-model="model.client_id"
                  name="client"
                  multiple
                  filterable
                  placeholder="Select Clients">
                  <el-option
                    v-for="client in clients"
                    :key="client.id"
                    :value="client.id"
                    :label="client.name"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <base-input
                label="Appointment Date/Time"
                type="datetime-local"
                v-model="model.datetime"
                id="example-datetime-local-input"
                />
      </form>

      <template slot="footer">
        <button type="submit" class="btn btn-primary new-event--add" @click="saveEvent">Add appointment</button>
        <button type="button" class="btn btn-link ml-auto" @click="showAddModal = false">Close</button>
      </template>
    </modal>

    <!-- Edit modal -->
    <modal :show.sync="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form" @submit.prevent="editEvent">
        <base-input disabled label="Appointment Name"
                    placeholder="Appointment"
                    v-model="model.title"
                    input-classes="form-control-alternative new-event--title">
        </base-input>
        <base-input v-if=this.$store.state.isAdmin disabled label="Client"
                    placeholder="Client"
                    v-model="model.client"
                    input-classes="form-control-alternative new-event--title">
        </base-input>
      </form>

      <template slot="footer">
        <!-- <base-button native-type="submit" type="primary" class="new-event--add" @click="editEvent">Update</base-button> -->
        <base-button v-if=this.$store.state.isAdmin type="danger" @click="deleteEvent">Delete</base-button>
        <!-- <base-button type="link" class="ml-auto" @click="showAddModal = false">Close</base-button> -->
      </template>
    </modal>
  </div>
</template>
<script>
  import Modal from '@/components/Modal'
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import { Select, Option } from "element-ui";

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();
  export default {
    name: 'calendar',
    components: {
      Modal,
      FullCalendar,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      let yearAndMonth = `${y}-${m + 1}`
      return {
        clients: [],
        currentMonth: '',
        selectedClient: null,
        available_roles: [],
        calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultView: 'dayGridMonth',
        appointments: [],
        events: [],
        showAddModal: false,
        showEditModal: false,
        model: {
          type: 'appointments',
          name: '',
          title: '',
          appointment_date: '',
          appointment_time: '',
          client_id: '',
          project_id: '0',
          datetime: '',
          start: '',
          end: ''
        },
        eventColors: ['bg-info', 'bg-orange', 'bg-red', 'bg-green', 'bg-default', 'bg-blue', 'bg-purple', 'bg-yellow']
      };
    },
    created() {
      this.getClients();
      this.getEvents();     
    },
    mounted() {
      this.$nextTick(() => {
        this.currentMonth = this.transformMonthNumber(this.$refs.fullCalendar.getApi().getDate().getMonth() + 1) + " " + this.$refs.fullCalendar.getApi().getDate().getFullYear();
      });
    },
    methods: {
      async getClients() {
        if(!this.$store.state.isAdmin)
          return;

        try {

          let params = {
            include: "roles",
          };

          await this.$store.dispatch("users/list", params);
          this.clients = this.$store.getters["users/list"];
          this.clients = this.clients.filter((user) => {
            return user.name !== "Admin";
          });
        } catch(error) {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      },
      
      async getEvents()
      {
        try {
          let params = {
          };

          await this.$store.dispatch("appointments/list", params);
          this.appointments = this.$store.getters["appointments/list"];

          if(!this.$store.state.isAdmin)
          {
            await this.$store.dispatch("profile/me");
            let me = await this.$store.getters["profile/me"]

            this.appointments = this.appointments.filter((appt) => {
              let allClientsAppt = [];
              appt.allClients.map(e => allClientsAppt.push(e.toString()));

              return allClientsAppt.includes(me.id);
            });
          }

          this.appointments.map((appointment) => {
            let event = {
              ...appointment,
              title: appointment.name,
              extendedProps: {
                client_id: appointment.client_id,
                appointment_id: appointment.id
              },
              start: new Date(appointment.appointment_date * 1000),
              end: new Date(appointment.appointment_date * 1000),
              allDay: true
            }

            // Check if todays date, send reminder
            if(appointment.sentReminder == 0)
              this.sendReminder(appointment);

            this.events.push(JSON.parse(JSON.stringify(event)))
          });
        } catch(error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      },
      calendarApi() {
        return this.$refs.fullCalendar.getApi()
      },
      changeView(viewType) {
        this.defaultView = viewType
        this.calendarApi().changeView(viewType)
      },
      transformMonthNumber(number) {
        switch(number)
        {
          case 1:
            return "January";
            break;
          case 2:
            return "February";
            break;
          case 3:
            return "March";
            break;
          case 4:
            return "April";
            break;
          case 5:
            return "May";
            break;
          case 6:
            return "June";
            break;
          case 7:
            return "July";
            break;
          case 8:
            return "August";
            break;
          case 9:
            return "September";
            break;
          case 10:
            return "October";
            break;
          case 11:
            return "November";
            break;
          case 12:
            return "December";
            break;
        }
      },
      next() {
        this.calendarApi().next()
        this.currentMonth = this.transformMonthNumber(this.$refs.fullCalendar.getApi().getDate().getMonth() + 1) + " " + this.$refs.fullCalendar.getApi().getDate().getFullYear();
      },
      prev() {
        this.calendarApi().prev()
        this.currentMonth = this.transformMonthNumber(this.$refs.fullCalendar.getApi().getDate().getMonth() + 1) + " " + this.$refs.fullCalendar.getApi().getDate().getFullYear();
      },
      onDateClick({ date }) {
        this.model.client_id = "";
        this.model.title = "";
        this.model.name = "";
        this.model.type = 'appointments';

        let tzoffset = date.getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -1);
        
        this.model.datetime = localISOTime.slice(0, 16);

        this.showAddModal = true
        this.model.start = date
        this.model.end = date
      },
      onEventClick({ el, event }) {
        let client_name = "";
        let allClients = event.extendedProps.client_id.split(',');

        this.clients.map((client) => {
          if(allClients.includes(client.id))
            client_name += client.name + ", ";
        });

        client_name = client_name.substring(0, client_name.length - 2);

        this.model = {
          appointment_id: event.extendedProps.appointment_id,
          datetime: event.datetime,
          client_id: event.extendedProps.client_id,
          client: client_name,
          project_id: "0",
          title: event.title,
          name: event.title,
          start: event.start,
          end: event.end,
          type: 'appointments',
        }
        this.showEditModal = true
      },
      async saveEvent() {
        try {
          if (this.model.name) {
            let event = {
              ...this.model,
              title: this.model.name,
              allDay: true,
            }

            this.model.appointment_date = Math.floor(new Date(this.model.datetime).getTime() / 1000).toString();
            this.model.appointment_time = this.model.datetime.substring(this.model.datetime.length - 5).toString();

            this.events.push(JSON.parse(JSON.stringify(event)));

            await this.$store.dispatch("appointments/add", this.model);
            let appointmentGet = this.$store.getters["appointments/appointment"];

            const forEmail = {
              type: 'appointments',
              appointmentId: appointmentGet.id
            };

            await this.$store.dispatch("appointments/sendNewAppointmentNotification", forEmail);

            if(this.$parent.totalAppointments == 0)
              this.$parent.totalAppointments = 1;
            else
              this.$parent.totalAppointments += 1;

            this.model = {
              type: 'appointments',
              name: '',
              title: '',
              start: '',
              end: '',
              project_id: "0"
            }
          }
          this.showAddModal = false
        } catch(error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      },
      editEvent() {
        let index = this.events.findIndex(e => e.title === this.model.title)
        if (index !== -1) {
          this.events.splice(index, 1, this.model)
        }
        this.showEditModal = false
      },
      async deleteEvent() {
        let index = this.events.findIndex(e => e.title === this.model.title)
        if (index !== -1) {
          this.events.splice(index, 1)
        }
        this.showEditModal = false

        if(this.$parent.totalAppointments == 1)
          this.$parent.totalAppointments = "0";
        else
          this.$parent.totalAppointments -= 1;
          

        await this.$store.dispatch("appointments/destroy", this.model.appointment_id);
        this.$notify({
          type: "success",
          message: "Appointment deleted successfully.",
        });
      },
      async sendReminder(appointment) {
        let todayDate = new Date();
        let appointmentDate = new Date(parseFloat(appointment.appointment_date * 1000));

        let today = todayDate.getFullYear().toString() + todayDate.getMonth().toString() + todayDate.getDate().toString();
        let appointmentDay = appointmentDate.getFullYear().toString() + appointmentDate.getMonth().toString() + appointmentDate.getDate().toString();

        if(today == appointmentDay)
        {
          const forEmail = {
            type: 'appointments',
            appointmentId: appointment.id
          };

          await this.$store.dispatch("appointments/sendAppointmentReminder", forEmail);
        }
      }
    }
  };
</script>
<style lang="scss">
  @import "~@fullcalendar/core/main.css";
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import "~@/assets/sass/core/vendors/fullcalendar";
</style>
